<template>
    <div>
        <v-btn depressed tile class="primary" large @click="displayAddUnavailability()">
            <v-icon left>mdi-plus</v-icon>
            Nouvelle indisponibilité
        </v-btn>

        <Spinner v-if="isSpinnerDisplayed" />

        <v-row class="py-4">
            <v-col cols="12" md="6" lg="3">
                <v-row class="date-pickers">
                    <v-col cols="6">
                        <TextDatePicker
                            clearable
                            prepend-icon="mdi-calendar"
                            dense
                            hide-details
                            label="Début d'indisponibilité"
                            :disabled="!items || !items.length"
                            v-model="selectedStartDate"
                            :max="selectedEndDate"
                        />
                    </v-col>
                    <v-col cols="6">
                        <TextDatePicker
                            clearable
                            prepend-icon="mdi-calendar"
                            dense
                            hide-details
                            label="Fin d'indisponibilité"
                            :disabled="!items || !items.length"
                            v-model="selectedEndDate"
                            :min="selectedStartDate"
                        />
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6" lg="3">
                <TextSelectPicker
                    dense
                    clearable
                    hide-details
                    :disabled="!validators || !validators.length"
                    v-model="selectedValidators"
                    :items="validators"
                    label="Dernière action par"
                    no-data-text="Aucun résultat pour cette recherche."
                    multiple
                    :maxChipsDisplayed="1"
                    deletableChips
                />
            </v-col>
            <v-col cols="12" md="6" lg="3">
                <TextSelectPicker
                    dense
                    clearable
                    hide-details
                    no-data-text="Aucun résultat pour ce motif."
                    :disabled="!reasons || !reasons.length"
                    v-model="selectedReasons"
                    :items="reasons"
                    label="Motif"
                    multiple
                    :maxChipsDisplayed="1"
                    deletableChips
                />
            </v-col>
            <v-col cols="12" md="6" lg="3">
                <TextSelectPicker
                    dense
                    clearable
                    hide-details
                    no-data-text="Aucun résultat pour ce statut."
                    :disabled="!statuses || !statuses.length"
                    v-model="selectedStatuses"
                    :items="statuses"
                    label="Statut"
                    multiple
                    :maxChipsDisplayed="2"
                    deletableChips
                />
            </v-col>
        </v-row>
        <SingleActionModal
            v-if="isActionModalDisplayed"
            v-model="isActionModalDisplayed"
            :unavailability="selectedUnav"
            :action="unavAction"
            @unavs-update="onUnavsUpdate()"
        />

        <UnavHistoricModal
            v-if="isHistoricModalDisplayed && selectedUnavHistoric && selectedUnavHistoric.length"
            v-model="isHistoricModalDisplayed"
            :unavailabilityHistoric="selectedUnavHistoric"
        />

        <v-tooltip
            bottom
            :activator="selectedElement"
            v-show="isTooltipOpened"
            v-model="isTooltipOpened"
        >
            {{ selectedMessage }}
        </v-tooltip>

        <v-data-table
            v-if="items"
            :headers="headers"
            :items="items"
            :loading="isTableLoading"
            loading-text="Chargement des données..."
            no-data-text="Aucune donnée à afficher."
            no-results-text="Aucune donnée correspond à votre recherche."
            :footer-props="footerProps"
            :options="options"
            must-sort
        >
            <template v-slot:[`item.statut`]="{ item }">
                <td class="pl-1">
                    <StatusCellSection v-if="item" :item="item" />
                </td>
            </template>

            <template v-slot:[`item.nom_modification`]="{ item }">
                <span v-if="item.nom_modification">
                    {{ item.nom_modification }} {{ item.prenom_modification }}
                </span>
                <span v-else>Non soumis à validation</span>
            </template>

            <template v-slot:[`item.date_created`]="{ item }">
                {{ item.date_created | formatDateTime }}
            </template>

            <template v-slot:[`item.date_debut`]="{ item }">
                {{ item.date_debut | formatDateTime }}
            </template>

            <template v-slot:[`item.date_fin`]="{ item }">
                {{ item.date_fin | formatDateTime }}
            </template>

            <template v-slot:[`item.lib_absence`]="{ item }">
                <span>{{ item.indisponibilite }} - {{ item.lib_absence }}</span>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
                <td>
                    <div class="action-btn">
                        <v-btn icon>
                            <v-icon
                                @click.stop="showHistoryModal(item)"
                                @mouseover="
                                    showActionsTooltip($event, ACTIONS_INDISPO.SHOW_HISTORIC)
                                "
                            >
                                {{ ACTIONS_INDISPO_ICONS.SHOW_HISTORIC.icon }}
                            </v-icon>
                        </v-btn>
                    </div>

                    <div class="action-btn" v-if="canEdit(item)">
                        <v-btn icon>
                            <v-icon
                                @click.stop="editUnavailabity($event, item)"
                                @mouseover="showActionsTooltip($event, ACTIONS_INDISPO.EDITION)"
                            >
                                {{ ACTIONS_INDISPO_ICONS.EDITION.icon }}
                            </v-icon>
                        </v-btn>
                    </div>

                    <div class="action-btn" v-if="canCancel(item)">
                        <v-btn icon>
                            <v-icon
                                @click.stop="
                                    showActionModal($event, item, ACTIONS_INDISPO.ANNULATION)
                                "
                                @mouseover="showActionsTooltip($event, ACTIONS_INDISPO.ANNULATION)"
                            >
                                {{ ACTIONS_INDISPO_ICONS.ANNULATION.icon }}
                            </v-icon>
                        </v-btn>
                    </div>
                    <div class="action-btn" v-if="canAskToCancel(item)">
                        <v-btn icon>
                            <v-icon
                                @click.stop="
                                    showActionModal(
                                        $event,
                                        item,
                                        ACTIONS_INDISPO.DEMANDE_ANNULATION
                                    )
                                "
                                @mouseover="
                                    showActionsTooltip($event, ACTIONS_INDISPO.DEMANDE_ANNULATION)
                                "
                            >
                                {{ ACTIONS_INDISPO_ICONS.DEMANDE_ANNULATION.icon }}
                            </v-icon>
                        </v-btn>
                    </div>
                </td>
            </template>
        </v-data-table>

        <TableDataLegendSection />
    </div>
</template>

<script>
/* eslint-disable no-await-in-loop */
import { mapMutations, createNamespacedHelpers } from 'vuex';
import moment from 'moment';

import { DATE_TIME_FORMAT } from '@/constants/dateFormat';
import {
    STATUT_INDISPO,
    STATUT_INDISPO_LABEL,
    ACTIONS_INDISPO,
    ACTIONS_INDISPO_VERB,
    ACTIONS_INDISPO_ICONS,
    UNAV_RIGHTS,
} from '@/constants/unavailabilities';

import unavailabilitiesApi from '@/api/unavailabilities';
import myRequestsApi from '@/api/myRequests';
import unavailabilitiesService from '@/services/unavailabilities';

import UnavHistoricModal from '@/components/UnavHistoricModal.vue';
import SingleActionModal from '@/components/unavActions/SingleActionModal.vue';
import StatusCellSection from '@/components/sections/table/StatusCellSection.vue';
import TextSelectPicker from '@/components/pickers/TextSelectPicker.vue';
import TextDatePicker from '@/components/pickers/TextDatePicker.vue';

import { API_MAX_LIMIT } from '@/constants/shared';

import Spinner from '@/components/Spinner.vue';
import TableDataLegendSection from '@/components/sections/table/TableDataLegendSection.vue';

const addUnavNameSpace = createNamespacedHelpers('addUnavailability');
const addUnavStore = {
    mapMutations: addUnavNameSpace.mapMutations,
};

const employeeNameSpace = createNamespacedHelpers('employee');
const employeeStore = {
    mapState: employeeNameSpace.mapState,
};

const fileName = 'MyRequestsPage.vue';

export default {
    name: 'MyRequestsPage',

    components: {
        Spinner,
        UnavHistoricModal,
        SingleActionModal,
        StatusCellSection,
        TextSelectPicker,
        TextDatePicker,
        TableDataLegendSection,
    },

    data() {
        return {
            STATUT_INDISPO,
            ACTIONS_INDISPO,
            ACTIONS_INDISPO_VERB,
            ACTIONS_INDISPO_ICONS,

            isTableLoading: false,
            isSpinnerDisplayed: false,
            items: undefined,
            rightsOnUnavailabilities: undefined,

            isTooltipOpened: false,
            isActionModalDisplayed: false,
            isHistoricModalDisplayed: false,
            unavAction: undefined,
            selectedUnav: undefined,
            selectedMessage: undefined,
            selectedElement: null,

            selectedStartDate: '',
            selectedEndDate: '',
            selectedReasons: [],
            selectedValidators: [],
            selectedStatuses: [],

            headers: [
                {
                    text: 'Statut',
                    value: 'statut',
                    class: 'header',
                    width: '85px',
                    filter: (value) => {
                        return this.selectedStatuses && this.selectedStatuses.length
                            ? this.selectedStatuses.includes(value)
                            : true;
                    },
                },
                {
                    text: 'Date de la demande',
                    value: 'date_created',
                    class: 'header',
                    width: '175px',
                },
                {
                    text: 'Dernière action par',
                    value: 'nom_modification',
                    class: 'header',
                    width: '175px',
                    filter: (value) => {
                        return this.selectedValidators && this.selectedValidators.length
                            ? this.selectedValidators.includes(value)
                            : true;
                    },
                },
                {
                    text: 'Début indisponibilité',
                    value: 'date_debut',
                    class: 'header',
                    width: '175px',
                    filter: (value) => {
                        return this.selectedStartDate
                            ? moment(value).isAfter(moment(this.selectedStartDate))
                            : true;
                    },
                },
                {
                    text: 'Fin indisponibilité',
                    value: 'date_fin',
                    class: 'header',
                    width: '175px',
                    filter: (value) => {
                        return this.selectedEndDate
                            ? moment(value).isBefore(moment(this.selectedEndDate))
                            : true;
                    },
                },
                {
                    text: 'Motif',
                    value: 'lib_absence',
                    class: 'header',
                    width: '175px',
                    filter: (value) => {
                        return this.selectedReasons && this.selectedReasons.length
                            ? this.selectedReasons.includes(value)
                            : true;
                    },
                },
                {
                    text: 'Actions',
                    value: 'actions',
                    class: 'header',
                    sortable: false,
                    width: '125px',
                },
            ],

            footerProps: {
                itemsPerPageText: 'Élements par page : ',
                itemsPerPageOptions: [10, 20, 30],
                pageText: '{0}-{1} sur {2}',
            },

            options: {
                itemsPerPage: 10,
                sortBy: ['date_created'],
                sortDesc: [true],
            },
        };
    },

    computed: {
        ...employeeStore.mapState(['employee']),

        validators() {
            return this.items
                ? this.items.map((item) => ({
                      text: `${item.nom_modification} ${item.prenom_modification}`,
                      value: item.nom_modification,
                  }))
                : [];
        },
        reasons() {
            return this.items ? this.items.map((item) => item.lib_absence) : [];
        },
        statuses() {
            return this.items
                ? this.items.map((item) => ({
                      text: STATUT_INDISPO_LABEL[item.statut],
                      value: item.statut,
                  }))
                : [];
        },
    },

    filters: {
        formatDateTime(date) {
            return date ? moment(date).format(DATE_TIME_FORMAT) : '';
        },
    },

    async created() {
        this.showSpinner();
        await this.notifyOnEnter();
        await this.loadData();
        this.hideSpinner();
    },

    methods: {
        ...mapMutations(['showErrorToast', 'clearErrors']),
        ...addUnavStore.mapMutations(['displayAddUnavailability', 'displayEditUnavailability']),

        async loadData() {
            try {
                this.showLoading();
                let data = await myRequestsApi.getMyUnavailabilities(0, 50);
                this.items = data.items;

                while (data.hasMore) {
                    const offset = data.offset + data.limit;
                    data = await myRequestsApi.getMyUnavailabilities(offset, API_MAX_LIMIT);
                    this.items = this.items.concat(data.items);
                }

                this.rightsOnUnavailabilities = {};
                this.items.forEach((unavailability) => {
                    this.rightsOnUnavailabilities[unavailability.id] =
                        unavailabilitiesService.getUnavRights(
                            this.employee,
                            this.employee,
                            unavailability
                        );
                });

                this.hideLoading();
            } catch (error) {
                this.hideLoading();
                this.hideSpinner();
                this.showErrorToast({ error, fileName });
            }
        },
        async notifyOnEnter() {
            try {
                await myRequestsApi.notifyMyUnavailabilitiesEvent();
            } catch (error) {
                this.showErrorToast({ error, fileName });
            }
        },

        async showHistoryModal(item) {
            try {
                this.showSpinner();
                this.selectedUnavHistoric = await unavailabilitiesApi.getUnavailabilityHistoric(
                    item.id
                );
                this.isHistoricModalDisplayed = true;
                this.hideSpinner();
            } catch (error) {
                this.hideSpinner();
                this.showErrorToast({ error, fileName });
            }
        },
        showActionModal(nativeEvent, unav, action) {
            this.hideTooltip();

            this.selectedUnav = unav;
            this.selectedMessage = undefined;
            this.selectedElement = nativeEvent.target;
            this.unavAction = action;

            this.displayActionModal();
            nativeEvent.stopPropagation();
        },
        showActionsTooltip(nativeEvent, action) {
            this.showEvent({
                nativeEvent,
                unav: undefined,
                message: ACTIONS_INDISPO_VERB[action],
            });
        },

        showEvent({ nativeEvent, unav, message }) {
            const open = () => {
                this.selectedUnav = unav;
                this.selectedMessage = message;
                this.selectedElement = nativeEvent.target;

                if (message) {
                    requestAnimationFrame(() => requestAnimationFrame(() => this.showTooltip()));
                }
            };

            if (this.isTooltipOpened) {
                this.hideTooltip();

                requestAnimationFrame(() => requestAnimationFrame(() => open()));
            } else {
                open();
            }
            nativeEvent.stopPropagation();
        },

        editUnavailabity(nativeEvent, unav) {
            this.hideTooltip();
            this.selectedUnav = unav;
            this.selectedMessage = undefined;
            this.selectedElement = nativeEvent.target;
            this.unavAction = undefined;

            this.displayEditUnavailability(unav);
            nativeEvent.stopPropagation();
        },
        canCancel(unavailability) {
            return (
                this.rightsOnUnavailabilities &&
                this.rightsOnUnavailabilities[unavailability.id].includes(UNAV_RIGHTS.CAN_CANCEL)
            );
        },
        canAskToCancel(unavailability) {
            return (
                this.rightsOnUnavailabilities &&
                this.rightsOnUnavailabilities[unavailability.id].includes(
                    UNAV_RIGHTS.CAN_ASK_TO_CANCEL
                )
            );
        },
        canEdit(unavailability) {
            return (
                this.rightsOnUnavailabilities &&
                this.rightsOnUnavailabilities[unavailability.id].includes(UNAV_RIGHTS.CAN_EDIT)
            );
        },

        displayActionModal() {
            this.isActionModalDisplayed = true;
        },
        showTooltip() {
            this.isTooltipOpened = true;
        },
        hideTooltip() {
            this.isTooltipOpened = false;
        },
        showLoading() {
            this.isTableLoading = true;
        },
        hideLoading() {
            this.isTableLoading = false;
        },
        showSpinner() {
            this.isSpinnerDisplayed = true;
        },
        hideSpinner() {
            this.isSpinnerDisplayed = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors';

::v-deep .header {
    background-color: $very-light-gray;
}
.action-btn {
    display: inline;
}

.date-pickers {
    ::v-deep .v-input {
        background-color: transparent;
    }
}
</style>
